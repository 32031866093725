import { ApiToken, Item, Secrets } from "@/types";

// TODO: handle server errors from api
class BackendClient {
  public async token(): Promise<ApiToken> {
    const resp = await fetch("/api/token");
    return (await resp.json()) as ApiToken;
  }

  public async secrets(authToken: string): Promise<Secrets> {
    const resp = await fetch(`/api/secrets`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    });
    return (await resp.json()) as Secrets;
  }

  public async getItems(authToken: string): Promise<Item[]> {
    const resp = await fetch(`/api/items`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    });
    return (await resp.json()) as Item[];
  }

  public async delItem(authToken: string, key: string) {
    await fetch(`/api/item/${key}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
    });
    // TODO error handling of delItem should return 204
  }

  public async addItem(authToken: string, key: string, value: string) {
    await fetch(`/api/item/${key}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({ value }),
    });
    // TODO error handling of addItem should return 201
  }
}

export default new BackendClient();
