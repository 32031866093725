import { defineStore } from "pinia";
import { ethers } from "ethers";
import bClient from "@/services/backendClient";
import { Secrets } from "@/types";

// TODO implement token timeout
export const useUserStore = defineStore("user", {
  state: () => {
    return {
      account: "",
      authToken: "",
      secrets: {} as Secrets,
    };
  },
  getters: {
    userLogged: (state) => state.account !== "",
  },
  actions: {
    async login() {
      // TODO: Maybe create separate service for web3 and use https://github.com/web3modal/web3modal
      if (typeof window.ethereum === "undefined") {
        console.log("MetaMask is not installed!");
      } else {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const token = await bClient.token();
        const client_signature = await signer.signMessage(token["expiration"]);

        // TODO when logging in authToken should be saved in the browser and restored.
        this.authToken = btoa(JSON.stringify({ client_signature, ...token }));
        this.secrets = await bClient.secrets(this.authToken);
        this.account = await signer.getAddress();
      }
    },
  },
});
