import { defineStore } from "pinia";
import bClient from "@/services/backendClient";
import { Item } from "@/types";
import { useUserStore } from "@/store/user";
import ipfsClient from "@/services/ipfsClient";
import { AddProgressFn } from "ipfs-core-types/src/root";

interface IpfsState {
  key: string;
  value?: string;
  inIpfs: boolean;
  inBackend: boolean;
}

export const useItemsStore = defineStore("items", {
  state: () => {
    return {
      ipfsState: [] as IpfsState[],
      items: [] as Item[],
      pins: [] as string[],
    };
  },

  getters: {
    ipfsMissingIpfs: (state) => state.ipfsState.filter((i) => !i.inIpfs),
    ipfsInIpfs: (state) => state.ipfsState.filter((i) => i.inIpfs),
  },

  actions: {
    async loadItems() {
      const userStore = useUserStore();
      this.items = await bClient.getItems(userStore.authToken);
    },

    async deleteItem(key: string) {
      const userStore = useUserStore();
      await bClient.delItem(userStore.authToken, key);
      this.items = this.items.filter((i) => i.key !== key);
      this.combineStores();
    },

    async setItem(key: string, value: string) {
      const userStore = useUserStore();
      await bClient.addItem(userStore.authToken, key, value);
    },

    async loadPins() {
      const userStore = useUserStore();
      this.pins = await ipfsClient.getPins(userStore.secrets.infura.projectId, userStore.secrets.infura.projectSecret);
    },

    combineStores() {
      this.ipfsState = this.pins.map((p) => ({
        key: p,
        value: undefined,
        inIpfs: true,
        inBackend: false,
      }));
      const keys = this.ipfsState.map((i) => i.key);
      for (const item of this.items) {
        if (keys.includes(item.key)) {
          const ipfs = this.ipfsState.find((i) => i.key === item.key) as IpfsState;
          ipfs.value = item.value;
          ipfs.inBackend = true;
        } else {
          this.ipfsState.push({ ...item, inBackend: true, inIpfs: false });
        }
      }
    },

    async getAll() {
      await Promise.all([this.loadItems(), this.loadPins()]);
      this.combineStores();
    },

    async putPin(name: string, data: string | ReadableStream, progress?: AddProgressFn) {
      const userStore = useUserStore();
      const ipfsHash = await ipfsClient.putPin(
        data,
        userStore.secrets.infura.projectId,
        userStore.secrets.infura.projectSecret,
        progress
      );
      console.log(ipfsHash);
      await this.setItem(ipfsHash, name);
      await this.getAll();
    },

    async removePin(ipfsHash: string) {
      const userStore = useUserStore();
      await ipfsClient.removePin(ipfsHash, userStore.secrets.infura.projectId, userStore.secrets.infura.projectSecret);
      await this.deleteItem(ipfsHash);
      await this.getAll();
    },
  },
});
