import { Options } from "ipfs-http-client";

const local = process.env.NODE_ENV === "development";

// TODO: Config ipfsOptions vValues should not be hardcoded
let ipfsOptions: Options = {
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
};

if (local) {
  ipfsOptions = {
    apiPath: "/ipfs/api/v0",
  };
}

const ipfsUrl = local ? "http://localhost:8080/ipfs/" : "https://ipfs.infura.io/ipfs/";

export const config = {
  ipfsUrl,
  ipfsOptions,
};
