import { create } from "ipfs-http-client";
import { AddProgressFn } from "ipfs-core-types/src/root";
import { config } from "@/config";

class IpfsClient {
  // TODO: handle errors from IPFS client

  public async getPins(projectId: string, projectSecret: string): Promise<string[]> {
    const opts = {
      ...config.ipfsOptions,
      headers: {
        authorization: `Basic ${btoa(`${projectId}:${projectSecret}`)}`,
      },
    };
    const ipfsClient = create(opts);
    const pinsIterator = await ipfsClient.pin.ls({ type: "recursive" });
    const arr = [];
    for await (const { cid } of pinsIterator) arr.push(cid.toV0().toString());
    return arr;
  }

  public async putPin(
    data: string | ReadableStream,
    projectId: string,
    projectSecret: string,
    progress?: AddProgressFn
  ): Promise<string> {
    const opts = {
      ...config.ipfsOptions,
      headers: {
        authorization: `Basic ${btoa(`${projectId}:${projectSecret}`)}`,
      },
    };
    const ipfsClient = create(opts);
    return (await ipfsClient.add(data, { progress })).path;
  }

  public async removePin(ipfsHash: string, projectId: string, projectSecret: string) {
    const opts = {
      ...config.ipfsOptions,
      headers: {
        authorization: `Basic ${btoa(`${projectId}:${projectSecret}`)}`,
      },
    };
    const ipfsClient = create(opts);
    await ipfsClient.pin.rm(ipfsHash);
  }
}

export default new IpfsClient();
